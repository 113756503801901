import { useContext, useState, useEffect, useRef } from "react";
import FileInput from "../../../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../../../api";
import { AuthContext } from "../../../context";
import { alertBox } from "../../../utils/AlertDailog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";
import Loader from "../../../components/Loader";
import {
  Permission,
  PermissionForDelete,
} from "../../../utils/PermissionDialog";

// const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const googleMapApiKey = "AIzaSyCTtJohN1NQgNOQkUJXisG0AFjMvCNPjyM";

function InstallCreateForm() {
  const { allWorkId } = useParams();
  console.log(allWorkId);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const [workDetail, setWorkDetail] = useState({});
  const [additionWorkDetail, setAdditionWorkDetail] = useState([]);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");

  const [previewImages, setPreviewImages] = useState([]);
  const [locationData, setLocationData] = useState(null);

  const canvasRef = useRef(null);

  const allWorkDetailsFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/install/work/image/${allWorkId}`
      );
      setWorkDetail(response.data.data);

      setAdditionWorkDetail(response.data.data);
      setShopName(response.data.data[0].allWorkId.shop_name);
      setShopAddress(response.data.data[0].allWorkId.address);
    } catch (error) {}
  };

  useEffect(() => {
    allWorkDetailsFn();
  }, []);
  const imageHandler = (event, index) => {
    // alert("aaa")
    let task = [...additionWorkDetail];
    const formData = new FormData();
    formData.append("uploadedFile", event.target.files[0]);
    task[index].imageUrl = URL.createObjectURL(event.target.files[0]);
    task[index].imgFile = formData;

    // for canvas image preview upload

    if (previewImages[index] === "deleted") {
      const newPreviewImages = [...previewImages];
      newPreviewImages[index] = "uploadingNew";

      // Update the previewImages state
      setPreviewImages(newPreviewImages);
    }

    setAdditionWorkDetail(task);
  };

  // const createCanvasForImageToSaveToServer = (
  //   imageUrl,
  //   locationData,
  //   placeName
  // ) => {
  //   return new Promise((resolve, reject) => {
  //     alert("ccc")
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");

  //     const img = new Image();
  //     img.crossOrigin = "anonymous"; // Allow cross-origin image loading
  //     img.src = imageUrl;

  //     img.onload = () => {
  //       // Set canvas size to match the image
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       // Draw the uploaded image onto the canvas
  //       ctx.drawImage(img, 0, 0);

  //       // Create the blob from canvas
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           resolve(blob); // Successfully created blob
  //         } else {
  //           reject("Blob creation failed");
  //         }
  //       }, "image/png");
  //     };

  //     img.onerror = () => reject("Image loading failed");
  //   });
  // };

  const createCanvasForImageToSaveToServer = (imageUrl, locationData, placeName) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageUrl;

    img.onload = () => {
      // Resize canvas to a maximum width (e.g., 1920px) while preserving aspect ratio
      const maxWidth = 1920; // Adjust as needed
      const scale = Math.min(maxWidth / img.width, 1); // Scale down if larger than maxWidth
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      // Draw the image on the resized canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Add map and text overlay here (call drawGmap-like logic if needed)
      // For simplicity, assuming overlay is added elsewhere

      // Convert to JPEG with compression
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject("Blob creation failed");
          }
        },
        "image/jpeg", // Use JPEG instead of PNG
        0.85 // Quality (0.0 to 1.0, 0.85 is a good balance)
      );
    };

    img.onerror = () => reject("Image loading failed");
  });
};
  const uploadImage = async () => {
    try {
      // alert("bbbbb")
      setisLoading(true); // Show the loader
      const allImagePayload = additionWorkDetail
        .map((item, index) => {
          if (item?.imgFile) {
            return createCanvasForImageToSaveToServer(
              previewImages[index],
              locationData
            )
              .then((blob) => {
                const formData = new FormData();
                formData.append("uploadedFile", blob);
                console.log("formData", formData);
                console.log("blob", blob);

                // Upload the formData
                return axios.post(
                  `${base_url}/api/install/work/image/${item._id}`,
                  formData
                );
              })
              .catch((error) => {
                console.error("Error creating canvas or uploading:", error);
                return null; // Or handle the error accordingly
              });
          } else return false;
        })
        .filter(Boolean);
      // await new Promise((resolve) => setTimeout(resolve, 1 * 60 * 1000)); // Delay for 1 minutes
      const res = await Promise.all(allImagePayload);

      // After uploading images, send shop name and address to Telegram
      await axios.post(`${base_url}/api/install/sendTelegramMessage`, {
        shopName: shopName,
        shopAddress: shopAddress,
      });

      console.log(res);
      alertBox({
        data: { message: "Saved Successfully" },
      });
      navigate("/installCompanyEmployeeList");
    } catch (error) {
      console.log(error);
      alertBox({
        data: { message: "Something went wrong ..." },
      });
    }
  };
  const getImageUrl = (fileUrl) => {
    if (typeof fileUrl === "object") {
      return URL.createObjectURL(fileUrl?.get("uploadedFile"));
    } else return `${base_url}/images/${fileUrl}`;
  };

  // Fetch location (geolocation)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationData({ latitude, longitude });
        },
        (error) => console.error("Error fetching location:", error)
      );
    }
  }, []);

  // this function only for the getting address in text
  const fetchMapAddressDetails = () => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;

    return fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;

          let city = "";
          let state = "";
          let country = "";

          addressComponents.forEach((component) => {
            const types = component.types;

            // Get city/locality
            if (types.includes("locality")) {
              city = component.long_name;
            }

            // Get state/administrative area
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }

            // Get country
            if (types.includes("country")) {
              country = component.long_name;
            }
          });

          const placeName = data.results[0].formatted_address; // This gives the full address

          return {
            fullPlaceName: placeName,
            city,
            state,
            country,
          };
        }
      })
      .catch((error) => console.error("Error fetching place name:", error));
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    return {
      localTime: currentDate.toLocaleString("en-US", {
        hour12: true,
      }),
    };
  };
  // const drawGmap = async (imgUrl, index) => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   console.log(ctx);
  //   const placeName = await fetchMapAddressDetails();

  //   // Create a new Image object for the uploaded image
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = imgUrl;

  //   img.onload = async () => {
  //     const imageAspectRatio = img.width / img.height;
  //     console.log({ imageAspectRatio });
  //     let paddingForBgRect;
  //     let paddingForGmapImg;

  //     let canvasWidth, canvasHeight;
  //     let rectBgHeight;
  //     let mapImgHeight;
  //     let mapImgWidth;
  //     let xPositionFirstText;
  //     let yPositionFirstText;
  //     let xPositionSecondText;
  //     let yPositionSecondText;

  //     let xPositionThirdText;
  //     let yPositionThirdText;

  //     let xPositionFourthText;
  //     let yPositionFourthText;

  //     let xPositionFifthText;
  //     let yPositionFifthText;

  //     let fontSizeFirst;
  //     let fontSizeRemaining;

  //     if (imageAspectRatio >= 16 / 9) {
  //       console.log("=>16 / 9");
  //       paddingForGmapImg = 15;
  //       paddingForBgRect = 15;

  //       canvasWidth = img.width; // Set a fixed width
  //       canvasHeight = img.height;
  //       rectBgHeight = 190;
  //       mapImgHeight = 190 - 2 * paddingForGmapImg;
  //       mapImgWidth = 190 - 2 * paddingForGmapImg;

  //       // first text
  //       xPositionFirstText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFirstText =
  //         canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 4;

  //       // second text
  //       xPositionSecondText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionSecondText =
  //         canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 6;

  //       // third text
  //       xPositionThirdText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionThirdText =
  //         canvasHeight - rectBgHeight - paddingForBgRect + paddingForBgRect * 8;

  //       // fourth text
  //       xPositionFourthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFourthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 9.9;

  //       // fifth text
  //       xPositionFifthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionFifthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 12;

  //       fontSizeFirst = canvasWidth * 0.02 + 4;
  //       fontSizeRemaining = canvasWidth * 0.02 - 5;
  //     } else if (imageAspectRatio >= 4 / 3) {
  //       console.log("=>4/3");
  //       paddingForGmapImg = 15;
  //       paddingForBgRect = 15;

  //       canvasWidth = img.width; // Set a fixed width
  //       canvasHeight = img.height;
  //       rectBgHeight = 200;
  //       mapImgHeight = 200 - 2 * paddingForGmapImg;
  //       mapImgWidth = 200 - 2 * paddingForGmapImg;
  //       xPositionFirstText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFirstText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 4.5;

  //       // second text
  //       xPositionSecondText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionSecondText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 6.5;

  //       // third text
  //       xPositionThirdText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionThirdText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 8.3;

  //       // fourth text
  //       xPositionFourthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFourthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 10;

  //       // fifth text
  //       xPositionFifthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionFifthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 12;

  //       fontSizeFirst = canvasWidth * 0.02 + 4;
  //       fontSizeRemaining = canvasWidth * 0.02 - 5;
  //     } else if (imageAspectRatio === 1) {
  //       console.log("=>1");
  //       paddingForGmapImg = 15;
  //       paddingForBgRect = 15;

  //       canvasWidth = img.width; // Set a fixed width
  //       canvasHeight = img.height;
  //       rectBgHeight = 200;
  //       mapImgHeight = 200 - 2 * paddingForGmapImg;
  //       mapImgWidth = 200 - 2 * paddingForGmapImg;
  //       xPositionFirstText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFirstText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 4.5;

  //       // second text
  //       xPositionSecondText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionSecondText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 6.5;

  //       // third text
  //       xPositionThirdText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionThirdText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 8.3;

  //       // fourth text
  //       xPositionFourthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //       yPositionFourthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 10;

  //       // fifth text
  //       xPositionFifthText =
  //         paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //       yPositionFifthText =
  //         canvasHeight -
  //         rectBgHeight -
  //         paddingForBgRect +
  //         paddingForBgRect * 12;

  //       fontSizeFirst = canvasWidth * 0.02 + 4;
  //       fontSizeRemaining = canvasWidth * 0.02 - 5;
  //     } else {
  //       console.log("=>tall", imageAspectRatio);

  //       paddingForGmapImg = 3;
  //       paddingForBgRect = 3;

  //       canvasWidth = img.width; // Set a fixed width
  //       canvasHeight = img.height;
  //       if (imageAspectRatio <= 0.5) {
  //         console.log("hiii, imageAspectRatio <= 0.5 ");

  //         rectBgHeight = 160;
  //         mapImgHeight = 140 - 2 * paddingForGmapImg;
  //         mapImgWidth = 140 - 2 * paddingForGmapImg;
  //         xPositionFirstText =
  //           paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //         yPositionFirstText =
  //           canvasHeight -
  //           rectBgHeight -
  //           paddingForBgRect +
  //           paddingForBgRect * 17.5;

  //         // second text
  //         xPositionSecondText =
  //           paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //         yPositionSecondText =
  //           canvasHeight -
  //           rectBgHeight -
  //           paddingForBgRect +
  //           paddingForBgRect * 23.5;
  //       } else {
  //         console.log("hiii, imageAspectRatio else");

  //         rectBgHeight = 180;
  //         mapImgHeight = 160 - 2 * paddingForGmapImg;
  //         mapImgWidth = 160 - 2 * paddingForGmapImg;

  //         xPositionFirstText =
  //           paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //         yPositionFirstText =
  //           canvasHeight -
  //           rectBgHeight -
  //           paddingForBgRect +
  //           paddingForBgRect * 17.5;

  //         // second text
  //         xPositionSecondText =
  //           paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //         yPositionSecondText =
  //           canvasHeight -
  //           rectBgHeight -
  //           paddingForBgRect +
  //           paddingForBgRect * 24.5;
  //       }

  //       // remaining in the inner split text function
  //       fontSizeFirst = canvasWidth * 0.02 + 3;
  //       fontSizeRemaining = canvasWidth * 0.02;
  //     }

  //     canvas.width = canvasWidth;
  //     canvas.height = canvasHeight;

  //     // Draw the uploaded image
  //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //     // Draw Google Map Image using dummy location
  //     if (locationData) {
  //       const mapImg = new Image();
  //       mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=200x200&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;

  //       mapImg.crossOrigin = "anonymous";
  //       mapImg.onload = () => {
  //         console.log("k");

  //         // for background fill
  //         ctx.fillStyle = "rgba(0, 0, 0, 0.357)";

  //         const rectBgWidth = canvas.width - 2 * paddingForBgRect;

  //         ctx.fillRect(
  //           paddingForBgRect + mapImgWidth + 8,
  //           canvas.height - rectBgHeight - paddingForBgRect + 28,
  //           rectBgWidth - mapImgWidth,
  //           rectBgHeight - 2 * paddingForBgRect
  //         ); // x, y, width and height

  //         // for google map image
  //         ctx.drawImage(
  //           mapImg,
  //           paddingForGmapImg,
  //           canvas.height - mapImgHeight - paddingForGmapImg,
  //           mapImgWidth,
  //           mapImgHeight
  //         ); // img, xleft, yBottom, imagewidth, imageheight

  //         ctx.font = `bold ${fontSizeFirst}px Arial`;
  //         ctx.fillStyle = "white"; // Text color

  //         ctx.fillText(
  //           `${placeName.city}, ${placeName.state}, ${placeName.country}`,
  //           xPositionFirstText,
  //           yPositionFirstText
  //         );

  //         // tall images
  //         if (imageAspectRatio <= 0.5) {
  //           console.log("imageAspectRatio <= 0.5");

  //           const fullAddress = `${placeName.fullPlaceName}`;
  //           const maxWidth = rectBgWidth - mapImgWidth - 2 * paddingForBgRect;
  //           const addressLines = splitTextIntoLines(
  //             fullAddress,
  //             maxWidth,
  //             ctx,
  //             `${fontSizeRemaining + 2}px Arial`
  //           );
  //           console.log({ addressLines });

  //           // Draw address line by line inside the rectangle
  //           addressLines.forEach((line, index) => {
  //             ctx.fillText(
  //               line,
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect, // x position inside the rectangle
  //               yPositionSecondText + 3 + index * 19 // y position based on line index
  //             );
  //           });

  //           if (addressLines.length >= 2) {
  //             console.log("addressLines.length >= 2");

  //             // third text
  //             xPositionThirdText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionThirdText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 37.5;

  //             // fourth text
  //             xPositionFourthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //             yPositionFourthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 44;

  //             // fifth text
  //             xPositionFifthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionFifthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 51;
  //           } else {
  //             console.log("addressLines.length else");

  //             // third text
  //             xPositionThirdText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionThirdText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 38.5;

  //             // fourth text
  //             xPositionFourthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //             yPositionFourthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 48;

  //             // fifth text
  //             xPositionFifthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionFifthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 58;
  //           }
  //         } else if (imageAspectRatio > 0.5 && imageAspectRatio <= 0.75) {
  //           console.log("imageAspectRatio <= 0.75", imageAspectRatio);

  //           const fullAddress = `${placeName.fullPlaceName}`;
  //           const maxWidth = rectBgWidth - mapImgWidth - 2 * paddingForBgRect;
  //           const addressLines = splitTextIntoLines(
  //             fullAddress,
  //             maxWidth,
  //             ctx,
  //             `${fontSizeRemaining + 2}px Arial`
  //           );
  //           console.log({ addressLines });

  //           // Draw address line by line inside the rectangle
  //           addressLines.forEach((line, index) => {
  //             ctx.fillText(
  //               line,
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect, // x position inside the rectangle
  //               yPositionSecondText + 3 + index * 26 // y position based on line index
  //             );
  //           });

  //           if (addressLines.length >= 2) {
  //             console.log("addressLines.length >= 2");

  //             // third text
  //             xPositionThirdText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionThirdText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 41.5;

  //             // fourth text
  //             xPositionFourthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //             yPositionFourthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 49.5;

  //             // fifth text
  //             xPositionFifthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionFifthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 57;
  //           } else {
  //             console.log("addressLines.length else");

  //             // third text
  //             xPositionThirdText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionThirdText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 38.5;

  //             // fourth text
  //             xPositionFourthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;

  //             yPositionFourthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 48;

  //             // fifth text
  //             xPositionFifthText =
  //               paddingForBgRect + mapImgWidth + 8 + paddingForBgRect;
  //             yPositionFifthText =
  //               canvasHeight -
  //               rectBgHeight -
  //               paddingForBgRect +
  //               paddingForBgRect * 58;
  //           }
  //         } else {
  //           console.log("imageAspectRatio in else");

  //           ctx.font = `${fontSizeRemaining}px Arial`; // Set responsive font size
  //           ctx.fillText(
  //             `${placeName.fullPlaceName}`,
  //             xPositionSecondText,
  //             yPositionSecondText
  //           );
  //         }

  //         // for latitude
  //         ctx.fillText(
  //           `Lat ${locationData?.latitude}°`,
  //           xPositionThirdText,
  //           yPositionThirdText
  //         );

  //         // for longitude
  //         ctx.fillText(
  //           `Long ${locationData?.longitude}°`,
  //           xPositionFourthText,
  //           yPositionFourthText
  //         );

  //         // for time
  //         const { localTime } = getCurrentTime();

  //         ctx.fillText(`${localTime}`, xPositionFifthText, yPositionFifthText);

  //         const newPreviewImages = [...previewImages];
  //         newPreviewImages[index] = canvas.toDataURL("image/png"); // Store image based on index
  //         console.log({ index });

  //         if (newPreviewImages[index] !== previewImages[index]) {
  //           setPreviewImages(newPreviewImages);
  //         }
  //       };
  //     }
  //   };
  // };

  // const drawGmap = async (imgUrl, index) => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   const placeName = await fetchMapAddressDetails();
  
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = imgUrl;
  
  //   img.onload = async () => {
  //     // Cap dimensions at 1054x1054
  //     const maxDimension = 1054;
  //     let newWidth = img.width;
  //     let newHeight = img.height;
  //     const aspectRatio = img.width / img.height;
  
  //     if (img.width > maxDimension || img.height > maxDimension) {
  //       if (aspectRatio > 1) {
  //         newWidth = maxDimension;
  //         newHeight = maxDimension / aspectRatio;
  //       } else {
  //         newHeight = maxDimension;
  //         newWidth = maxDimension * aspectRatio;
  //       }
  //     }
  
  //     canvas.width = newWidth;
  //     canvas.height = newHeight;
  
  //     // Draw the resized image
  //     ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
  //     // Adjust overlay sizes based on new dimensions
  //     const paddingForGmapImg = 10; // Reduced for smaller canvas
  //     const paddingForBgRect = 10;
  //     const rectBgHeight = Math.min(180, newHeight * 0.3); // Scale with canvas height
  //     const mapImgHeight = rectBgHeight - 2 * paddingForGmapImg;
  //     const mapImgWidth = mapImgHeight; // Square map
  
  //     const fontSizeFirst = Math.max(14, newWidth * 0.02); // Minimum font size
  //     const fontSizeRemaining = Math.max(12, newWidth * 0.015);
  //     const lineHeight = fontSizeRemaining * 1.5;
  
  //     // Draw Google Map
  //     if (locationData) {
  //       const mapImg = new Image();
  //       mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=200x200&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;
  //       mapImg.crossOrigin = "anonymous";
  
  //       mapImg.onload = () => {
  //         // Background rectangle
  //         ctx.fillStyle = "rgba(0, 0, 0, 0.357)";
  //         const rectBgWidth = newWidth - 2 * paddingForBgRect;
  //         ctx.fillRect(
  //           paddingForBgRect,
  //           newHeight - rectBgHeight - paddingForBgRect,
  //           rectBgWidth,
  //           rectBgHeight
  //         );
  
  //         // Draw map
  //         ctx.drawImage(
  //           mapImg,
  //           paddingForGmapImg,
  //           newHeight - mapImgHeight - paddingForGmapImg,
  //           mapImgWidth,
  //           mapImgHeight
  //         );
  
  //         // Text positioning
  //         const textX = paddingForBgRect + mapImgWidth + 10;
  //         let textY = newHeight - rectBgHeight + paddingForBgRect + 10;
  
  //         // City, state, country
  //         ctx.font = `bold ${fontSizeFirst}px Arial`;
  //         ctx.fillStyle = "white";
  //         ctx.fillText(
  //           `${placeName.city}, ${placeName.state}, ${placeName.country}`,
  //           textX,
  //           textY
  //         );
  //         textY += lineHeight;
  
  //         // Full address
  //         const fullAddress = `${placeName.fullPlaceName}`;
  //         const maxWidth = rectBgWidth - mapImgWidth - 20;
  //         ctx.font = `${fontSizeRemaining}px Arial`;
  //         const addressLines = splitTextIntoLines(fullAddress, maxWidth, ctx, ctx.font);
  //         addressLines.forEach((line) => {
  //           ctx.fillText(line, textX, textY);
  //           textY += lineHeight;
  //         });
  
  //         // Latitude, longitude, time
  //         ctx.fillText(`Lat ${locationData?.latitude}°`, textX, textY);
  //         textY += lineHeight;
  //         ctx.fillText(`Long ${locationData?.longitude}°`, textX, textY);
  //         textY += lineHeight;
  //         const { localTime } = getCurrentTime();
  //         ctx.fillText(`${localTime}`, textX, textY);
  
  //         // Update preview
  //         const newPreviewImages = [...previewImages];
  //         newPreviewImages[index] = canvas.toDataURL("image/jpeg", 0.85);
  //         setPreviewImages(newPreviewImages);
  //       };
  //     }
  //   };
  // };


  // const drawGmap = async (imgUrl, index) => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   const placeName = await fetchMapAddressDetails();
  
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = imgUrl;
  
  //   img.onload = async () => {
  //     // Set canvas size to match original image dimensions
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  
  //     // Draw original image (no compression or resizing)
  //     ctx.drawImage(img, 0, 0);
  
  //     // Overlay settings
  //     const paddingForGmapImg = 20; // Padding for map
  //     const paddingForBgRect = 20;
  //     const rectBgHeight = Math.min(180, img.height * 0.3); // Scale with image height
  //     const mapImgHeight = rectBgHeight - 2 * paddingForGmapImg;
  //     const mapImgWidth = mapImgHeight; // Square map
  
  //     const fontSizeFirst = Math.max(16, img.width * 0.02);
  //     const fontSizeRemaining = Math.max(14, img.width * 0.015);
  //     const lineHeight = fontSizeRemaining * 1.5;
  
  //     // Draw Google Map
  //     if (locationData) {
  //       const mapImg = new Image();
  //       mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=300x300&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;
  //       mapImg.crossOrigin = "anonymous";
  
  //       mapImg.onload = () => {
  //         // Background rectangle
  //         ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
  //         const rectBgWidth = img.width - 2 * paddingForBgRect;
  //         ctx.fillRect(
  //           paddingForBgRect,
  //           img.height - rectBgHeight - paddingForBgRect,
  //           rectBgWidth,
  //           rectBgHeight
  //         );
  
  //         // Draw Google Map
  //         ctx.drawImage(
  //           mapImg,
  //           paddingForGmapImg,
  //           img.height - mapImgHeight - paddingForGmapImg,
  //           mapImgWidth,
  //           mapImgHeight
  //         );
  
  //         // Text positioning
  //         const textX = paddingForBgRect + mapImgWidth + 20;
  //         let textY = img.height - rectBgHeight + paddingForBgRect + 20;
  
  //         // City, State, Country
  //         ctx.font = `bold ${fontSizeFirst}px Arial`;
  //         ctx.fillStyle = "white";
  //         ctx.fillText(
  //           `${placeName.city}, ${placeName.state}, ${placeName.country}`,
  //           textX,
  //           textY
  //         );
  //         textY += lineHeight;
  
  //         // Full Address
  //         const fullAddress = `${placeName.fullPlaceName}`;
  //         const maxWidth = rectBgWidth - mapImgWidth - 40;
  //         ctx.font = `${fontSizeRemaining}px Arial`;
  //         const addressLines = splitTextIntoLines(fullAddress, maxWidth, ctx, ctx.font);
  //         addressLines.forEach((line) => {
  //           ctx.fillText(line, textX, textY);
  //           textY += lineHeight;
  //         });
  
  //         // Latitude, Longitude, Time
  //         ctx.fillText(`Lat ${locationData?.latitude}°`, textX, textY);
  //         textY += lineHeight;
  //         ctx.fillText(`Long ${locationData?.longitude}°`, textX, textY);
  //         textY += lineHeight;
  //         const { localTime } = getCurrentTime();
  //         ctx.fillText(`${localTime}`, textX, textY);
  
  //         // Update preview (original size, no compression)
  //         const newPreviewImages = [...previewImages];
  //         newPreviewImages[index] = canvas.toDataURL("image/png"); // PNG maintains full quality
  //         setPreviewImages(newPreviewImages);
  //       };
  //     }
  //   };
  // };

  // const drawGmap = async (imgUrl, index) => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   const placeName = await fetchMapAddressDetails();
  
  //   const img = new Image();
  //   img.crossOrigin = "anonymous";
  //   img.src = imgUrl;
  
  //   img.onload = async () => {
  //     // Set canvas size to match original image dimensions
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  
  //     // Draw original image (no compression or resizing)
  //     ctx.drawImage(img, 0, 0);
  
  //     // Overlay settings
  //     const paddingForGmapImg = 20; // Padding for map
  //     const paddingForBgRect = 20;
  //     const rectBgHeight = Math.min(180, img.height * 0.3); // Scale with image height
  //     const mapImgHeight = rectBgHeight - 2 * paddingForGmapImg;
  //     const mapImgWidth = mapImgHeight; // Square map
  
  //     const fontSizeFirst = Math.max(16, img.width * 0.02);
  //     const fontSizeRemaining = Math.max(14, img.width * 0.015);
  //     const lineHeight = fontSizeRemaining * 1.5;
  
  //     // Draw Google Map
  //     if (locationData) {
  //       const mapImg = new Image();
  //       mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=300x300&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;
  //       mapImg.crossOrigin = "anonymous";
  
  //       mapImg.onload = () => {
  //         // Background rectangle
  //         ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
  //         const rectBgWidth = img.width - 2 * paddingForBgRect;
  //         ctx.fillRect(
  //           paddingForBgRect,
  //           img.height - rectBgHeight - paddingForBgRect,
  //           rectBgWidth,
  //           rectBgHeight
  //         );
  
  //         // Draw Google Map
  //         ctx.drawImage(
  //           mapImg,
  //           paddingForGmapImg,
  //           img.height - mapImgHeight - paddingForGmapImg,
  //           mapImgWidth,
  //           mapImgHeight
  //         );
  
  //         // Text positioning
  //         const textX = paddingForBgRect + mapImgWidth + 20;
  //         let textY = img.height - rectBgHeight + paddingForBgRect + 20;
  
  //         // City, State, Country
  //         ctx.font = `bold ${fontSizeFirst}px Arial`;
  //         ctx.fillStyle = "white";
  //         ctx.fillText(
  //           `${placeName.city}, ${placeName.state}, ${placeName.country}`,
  //           textX,
  //           textY
  //         );
  //         textY += lineHeight;
  
  //         // Full Address
  //         const fullAddress = `${placeName.fullPlaceName}`;
  //         const maxWidth = rectBgWidth - mapImgWidth - 40;
  //         ctx.font = `${fontSizeRemaining}px Arial`;
  //         const addressLines = splitTextIntoLines(fullAddress, maxWidth, ctx, ctx.font);
  //         addressLines.forEach((line) => {
  //           ctx.fillText(line, textX, textY);
  //           textY += lineHeight;
  //         });
  
  //         // Calculate available space for remaining text (Latitude, Longitude, Time)
  //         const remainingSpace = img.height - rectBgHeight - textY - paddingForBgRect;
  
  //         // If remaining space is too small, reduce font size
  //         const adjustedFontSizeRemaining = remainingSpace > lineHeight * 3 
  //           ? fontSizeRemaining 
  //           : Math.max(fontSizeRemaining * 0.8, 12);  // Scale down font if space is tight
  
  //         // Latitude, Longitude, Time
  //         ctx.font = `${adjustedFontSizeRemaining}px Arial`;
  //         ctx.fillText(`Lat ${locationData?.latitude}°`, textX, textY);
  //         textY += lineHeight;
  //         ctx.fillText(`Long ${locationData?.longitude}°`, textX, textY);
  //         textY += lineHeight;
  //         const { localTime } = getCurrentTime();
  //         ctx.fillText(`${localTime}`, textX, textY);
  
  //         // Update preview (original size, no compression)
  //         const newPreviewImages = [...previewImages];
  //         newPreviewImages[index] = canvas.toDataURL("image/png"); // PNG maintains full quality
  //         setPreviewImages(newPreviewImages);
  //       };
  //     }
  //   };
  // };
  
  const drawGmap = async (imgUrl, index) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const placeName = await fetchMapAddressDetails();
  
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imgUrl;
  
    img.onload = async () => {
      // Set canvas size to match original image dimensions
      canvas.width = img.width;
      canvas.height = img.height;
  
      // Draw original image (no compression or resizing)
      ctx.drawImage(img, 0, 0);
  
      // Overlay settings
      const paddingForGmapImg = 20; // Padding for map
      const paddingForBgRect = 20;
      const rectBgHeight = Math.min(180, img.height * 0.3); // Scale with image height
      const mapImgHeight = rectBgHeight - 2 * paddingForGmapImg;
      const mapImgWidth = mapImgHeight; // Square map
  
      const fontSizeFirst = Math.max(16, img.width * 0.02);
      const fontSizeRemaining = Math.max(14, img.width * 0.015);
      const lineHeight = fontSizeRemaining * 1.5;
  
      // Draw Google Map
      if (locationData) {
        const mapImg = new Image();
        mapImg.src = `https://maps.googleapis.com/maps/api/staticmap?center=${locationData.latitude},${locationData.longitude}&zoom=15&size=300x300&markers=color:red%7C%7C${locationData.latitude},${locationData.longitude}&key=${googleMapApiKey}`;
        mapImg.crossOrigin = "anonymous";
  
        mapImg.onload = () => {
          // Background rectangle
          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          const rectBgWidth = img.width - 2 * paddingForBgRect;
          ctx.fillRect(
            paddingForBgRect,
            img.height - rectBgHeight - paddingForBgRect,
            rectBgWidth,
            rectBgHeight
          );
  
          // Move map upwards by reducing the Y position
          const mapYPosition = img.height - mapImgHeight - paddingForGmapImg - 40; // Move map up 40px
  
          // Draw Google Map
          ctx.drawImage(
            mapImg,
            paddingForGmapImg,
            mapYPosition,
            mapImgWidth,
            mapImgHeight
          );
  
          // Text positioning
          const textX = paddingForBgRect + mapImgWidth + 20;
          let textY = img.height - rectBgHeight + paddingForBgRect + 20;
  
          // City, State, Country
          ctx.font = `bold ${fontSizeFirst}px Arial`;
          ctx.fillStyle = "white";
          ctx.fillText(
            `${placeName.city}, ${placeName.state}, ${placeName.country}`,
            textX,
            textY
          );
          textY += lineHeight;
  
          // Full Address
          const fullAddress = `${placeName.fullPlaceName}`;
          const maxWidth = rectBgWidth - mapImgWidth - 40;
          ctx.font = `${fontSizeRemaining}px Arial`;
          const addressLines = splitTextIntoLines(fullAddress, maxWidth, ctx, ctx.font);
          addressLines.forEach((line) => {
            ctx.fillText(line, textX, textY);
            textY += lineHeight;
          });
  
          // Latitude, Longitude, Time
          // Ensure we are calculating available space for remaining text
          const remainingSpace = img.height - rectBgHeight - textY - paddingForBgRect;
          const adjustedFontSizeRemaining = remainingSpace > lineHeight * 3 
            ? fontSizeRemaining 
            : Math.max(fontSizeRemaining * 0.8, 12);  // Scale down font if space is tight
  
          ctx.font = `${adjustedFontSizeRemaining}px Arial`;
          ctx.fillText(`Lat ${locationData?.latitude}°`, textX, textY);
          textY += lineHeight;
          ctx.fillText(`Long ${locationData?.longitude}°`, textX, textY);
          textY += lineHeight;
          const { localTime } = getCurrentTime();
          ctx.fillText(`${localTime}`, textX, textY);
  
          // Update preview (original size, no compression)
          const newPreviewImages = [...previewImages];
          newPreviewImages[index] = canvas.toDataURL("image/png"); // PNG maintains full quality
          setPreviewImages(newPreviewImages);
        };
      }
    };
  };
  

  // Helper function to split text into lines
  function splitTextIntoLines(text, maxWidth, ctx, font) {
    ctx.font = font;
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine); // Add the last line
    return lines;
  }
  const drawGoogleMapCanvasFunction = (fileUrl, index) => {
    if (typeof fileUrl === "object") {
      console.log("fileUrl in if", fileUrl);

      drawGmap(URL.createObjectURL(fileUrl?.get("uploadedFile")), index);
    } else {
      console.log("fileUrl in else", fileUrl);

      // return `${base_url}/images/${fileUrl}`;
    }
  };

  const deleteFn = async (additionalId, index) => {
    try {
      PermissionForDelete().then(async (res) => {
        if (res) {
          let response = await axios.delete(
            `${base_url}/api/install/work/image/${additionalId}`
          );
          alertBox({ data: { message: "Image Deleted Successfully" } });
          // allWorkDetailsFn();
          const newPreviewImages = [...previewImages];
          newPreviewImages[index] = "deleted";

          // Update the previewImages state
          setPreviewImages(newPreviewImages);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <div
            // onSubmit={handleSubmit}
            className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 "
          >
            <label className="font-bold" htmlFor="phone_no">
              {shopName}
            </label>
            {additionWorkDetail.map((item, index) => {
              return (
                <div
                  key={index}
                  className="border-4 border-l-cyan-500 flex  items-center  p-4"
                >
                  <div>
                    <p>Type: {item.requirment_type}</p>
                    <p>Width: {item.width} </p>
                    <p>Height: {item.height} </p>
                    <p>Quantity: {item.quantity} </p>

                    {item?.imgFile || item?.imageUrl ? (
                      <>
                        <canvas
                          ref={canvasRef}
                          style={{ display: "none" }}
                        ></canvas>
                        {previewImages[index] &&
                          previewImages[index] !== "deleted" && (
                            <img src={previewImages[index]} alt="Preview" />
                          )}
                        {console.log(
                          previewImages[index] === "deleted",
                          !previewImages[index],
                          previewImages[index] === "deleted" ||
                            !previewImages[index]
                        )}
                        {(previewImages[index] === "deleted" ||
                          previewImages[index] === "uploadingNew" ||
                          !previewImages[index]) && (
                          // eslint-disable-next-line jsx-a11y/alt-text
                          <img
                            className="hidden"
                            src={getImageUrl(item?.imgFile || item?.imageUrl)}
                            onLoad={() => {
                              console.log(
                                "previewImages[index]",
                                previewImages[index]
                              );

                              if (previewImages[index] === "deleted") {
                                // return null;
                              } else if (
                                previewImages[index] === "uploadingNew"
                              ) {
                                drawGoogleMapCanvasFunction(
                                  item?.imgFile || item?.imageUrl,
                                  index
                                );
                              } else {
                                drawGoogleMapCanvasFunction(
                                  item?.imgFile || item?.imageUrl,
                                  index
                                );
                              }
                            }}
                          />
                        )}

                        {previewImages[index] === "deleted" && (
                          <input
                            type="file"
                            id="shop_name_after_preview"
                            onChange={(event) => imageHandler(event, index)}
                            required
                          />
                        )}
                        {previewImages[index] &&
                          previewImages[index] !== "deleted" && (
                            <button
                              type="submit"
                              onClick={() => deleteFn(item._id, index)}
                              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                                isLoading ? "bg-slate-400" : ""
                              } `}
                              disabled={isLoading}
                            >
                              {/* {isLoading ? <Loader /> : "Delete"} */}
                              Delete
                            </button>
                          )}
                      </>
                    ) : (
                      <input
                        type="file"
                        id="shop_name"
                        onChange={(event) => imageHandler(event, index)}
                        // value={shop_name}
                        required
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => uploadImage()}
              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                isLoading ? "bg-slate-400" : ""
              } `}
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default InstallCreateForm;
